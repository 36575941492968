import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import Popover from '@mui/material/Popover';
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";


const months = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const statuses = {
  Paid: "text-green-700 bg-green-50 ring-green-600/20",
  Unpaid: "text-red-700 bg-red-50 ring-red-600/10",
};


// const cardData = [
//   { title: "Connected", key: "connected", image: "/link.png" },
//   { title: "First Lesson Complete", key: "firstLessonComplete", image: "/first.png" },
//   { title: "Won", key: "wonJobs", image: "/won.png" },
//   { title: "Packages", key: "packageCount", image: "/package.png" }, // Updated key for package count
//   { title: "Lost - Pre Connection", key: "lostPreConnection", image: "/lostpre.png" },
//   { title: "Lost - Post Connection", key: "lostPostConnection", image: "/lostpost.png" },
 
// ];

const firstRowCards = [
  { title: "Connected", key: "connected", image: "/link.png" },
  { title: "First Lesson Complete", key: "firstLessonComplete", image: "/first.png" },
  { title: "Won", key: "wonJobs", image: "/won.png" },
 // { title: "Packages", key: "packageCount", image: "/package.png" },
];

const secondRowCards = [
  { title: "Lost - Pre Connection", key: "lostPreConnection", image: "/lostpre.png" },
  { title: "Lost - First Session", key: "lostFirstSession", image: "/lostfirst.png" }, // Added field for Lost after the first lesson
  { title: "Lost - Post Connection", key: "lostPostConnection", image: "/lostpost.png" },
];



const shortcutsItems = [
  {
    label: "Today", // Changed label from "Last 7 Days" to "Today"
    getValue: () => {
      const today = dayjs();
      return [today.startOf("day"), today.endOf("day")]; // Set start and end to today
    },
  },
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  
  {
    label: "Last Month",
    getValue: () => {
      const today = dayjs();
      const lastMonth = today.subtract(1, "month");
      return [lastMonth.startOf("month"), lastMonth.endOf("month")];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "This Year",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("year"), today.endOf("year")];
    },
  },
  {
    label: "Last Year",
    getValue: () => {
      const today = dayjs();
      const lastYear = today.subtract(1, "year");
      return [lastYear.startOf("year"), lastYear.endOf("year")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

const Leaderboard = () => {
  const [clientManagers, setClientManagers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(null); // Add state for the logged-in user
  const [modifiedCommissions, setModifiedCommissions] = useState([]);
  const [selectedManager, setSelectedManager] = useState(null); // State for selected manager
  const [monthlyCommission, setMonthlyCommission] = useState([]); // To store the commission data for each month
  const [expandedRows, setExpandedRows] = useState({}); // State to track expanded rows
  const [lostFirstSessionCount, setLostFirstSessionCount] = useState(0); // Correctly declared here

  useEffect(() => {
    // Fetch Lost - First Session data (e.g., finished services with 1 lesson complete)
    const fetchLostFirstSession = async () => {
      try {
        const response = await axios.get('/api/services', { params: { status: 'finished' } });
        console.log("API Response for /api/services:", response.data); // Log the API response
    
        if (Array.isArray(response.data)) {
          const lostFirstSession = response.data.filter(service => service.completed_lessons === 1);
          setLostFirstSessionCount(lostFirstSession.length);
        } else {
          console.error("Expected an array but got something else:", response.data);
        }
      } catch (error) {
        console.error("Error fetching lost first session data:", error);
      }
    };
    
    

    fetchLostFirstSession();
  }, []);


  const [dateRange, setDateRange] = useState({
    startDate: dayjs().startOf("month"),
    endDate: dayjs().endOf("month"),
  });

  useEffect(() => {
    const fetchLoggedInUser = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found, redirecting to login");
          return;
        }
        const response = await axios.get("/api/current-user", {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        setLoggedInUser(response.data);
  
        // If logged-in user is a Client Manager, automatically set them as selectedManager
        if (response.data.role === "Client Manager") {
          setSelectedManager(response.data.email); // Set selectedManager to their own email
          fetchYearlyCommission(response.data.email); // Fetch their commission data
        }
      } catch (error) {
        console.error("Error fetching logged-in user:", error);
      }
    };
  
    fetchLoggedInUser();
  }, []);

  const handleManagerChange = (event) => {
    const managerEmail = event.target.value;
    setSelectedManager(managerEmail);
  
    // Clear current monthly commission state before fetching new data
    setMonthlyCommission([]); // Clear stale data
  
    fetchYearlyCommission(managerEmail); // Fetch the new manager's data
  };
  
  const fetchLeaderboardData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/leaderboard", {
        params: {
          startDate: dateRange.startDate.toISOString(),
          endDate: dateRange.endDate.toISOString(),
        },
      });
      console.log("Leaderboard API Response:", response.data); // Log the response
      setClientManagers(response.data);
    } catch (error) {
      console.error("Error fetching leaderboard data:", error.message);
    } finally {
      setLoading(false);
    }
  }, [dateRange]);
  

 

  const handleDateRangeChange = (newValue) => {
    if (newValue && newValue[0] && newValue[1]) {
      setDateRange({
        startDate: newValue[0].startOf("day"),
        endDate: newValue[1].endOf("day"),
      });
    }
  };

  const fetchYearlyCommission = useCallback(async (managerEmail) => {
    if (!managerEmail) return;
    setLoading(true);
    try {
      const response = await axios.get(`/commission/yearly/${managerEmail}`);
      console.log('Commission data:', response.data);  // Log response to verify
  
      const aggregatedCommission = response.data.reduce((acc, current) => {
        let { month_year, total_commission } = current;
  
        // Convert MM/YYYY to YYYY-MM
        const formattedMonthYear = dayjs(month_year, 'MM/YYYY').format('YYYY-MM');
  
        if (!formattedMonthYear || total_commission == null) {
          return acc;
        }
  
        const existingEntry = acc.find((item) => item.month_year === formattedMonthYear);
        if (existingEntry) {
          existingEntry.total_commission += parseFloat(total_commission);
        } else {
          acc.push({
            month_year: formattedMonthYear,
            total_commission: parseFloat(total_commission) || 0,
          });
        }
  
        return acc;
      }, []);
  
      setMonthlyCommission(aggregatedCommission || []);
    } catch (error) {
      console.error("Error fetching yearly commission:", error);
      setMonthlyCommission([]);
    } finally {
      setLoading(false);
    }
  }, []);
  
  

  // Fetch appointment data for the month
  const fetchAppointmentsForMonth = async (monthYear) => {
    try {
      const response = await axios.get(`/appointments/month/${monthYear}`);
      return response.data; // Assumes API returns appointment data for the given month
    } catch (error) {
      console.error("Error fetching appointments:", error);
      return [];
    }
  };

  // const fetchCommissionPayments = useCallback(async () => {
  //   try {
  //     const response = await axios.get("/api/commission-payments", {
  //       params: { manager: selectedManager },
  //     });
    
  //     // Log fetched commission data
  //     console.log("Fetched commission data:", response.data);
  
  //     // Update commission state from the backend, including the status
  //     const updatedCommission = response.data.map((commission) => ({
  //       month_year: commission.month_year,
  //       total_commission: parseFloat(commission.total_commission) || 0,
  //       paidStatus: commission.status === 'Paid' ? 'Paid' : 'Unpaid', // Use backend's status field
  //       payment_date: commission.payment_date // Ensure this field is returned from the backend
  //     }));
      
  
  //     setMonthlyCommission(updatedCommission);
  //   } catch (error) {
  //     console.error("Error fetching commission payments:", error);
  //   }
  // }, [selectedManager]);
  
  
  const fetchCommissionPayments = useCallback(async () => {
    try {
      const response = await axios.get("/api/commission-payments", {
        params: { manager: selectedManager },
      });
  
      const updatedCommission = response.data.map((commission) => ({
        month_year: commission.month_year,
        total_commission: parseFloat(commission.total_commission) || 0,
        paidStatus: commission.status === "Paid" ? "Paid" : "Unpaid",
        payment_date: commission.payment_date,  // Ensure this field is passed from backend
        appointments: commission.appointments || [],  // Ensure appointments are passed correctly
      }));
  
      setMonthlyCommission(updatedCommission);
    } catch (error) {
      console.error("Error fetching commission payments:", error);
    }
  }, [selectedManager]);
  
  
  

  useEffect(() => {
    if (selectedManager) {
      fetchCommissionPayments();
    }
  }, [fetchCommissionPayments, selectedManager]);

  
 // Toggle the expanded row to show or hide the related appointments
 const toggleRowExpanded = (monthYear) => {
  setExpandedRows((prev) => ({
    ...prev,
    [monthYear]: !prev[monthYear],
  }));
};

  
  
  // Ensure that useEffect comes after the fetchCommissionPayments definition
  useEffect(() => {
    if (selectedManager) {
      fetchCommissionPayments();
      console.log("Monthly Commission:", monthlyCommission); // Log the commission data to debug
    }
  }, [fetchCommissionPayments, selectedManager]);
  
  
  
  // const handleTogglePaidStatus = async (commissionData) => {
  //   try {
  //     const currentStatus = commissionData.paidStatus === "Paid" ? "Unpaid" : "Paid";
  //     const finalAmount = commissionData.total_commission || 0;
  
  //     // Log the data being sent
  //     console.log("Month being sent to backend:", commissionData.month_year);
  
  //     const payload = {
  //       manager: selectedManager,
  //       status: currentStatus,
  //       amount: finalAmount,
  //       month: commissionData.month_year,  // Ensure this is in MM/YYYY format
  //     };
  
  //     console.log("Payload being sent to backend:", payload);
  
  //     await axios.post(`/api/update-commission-status`, payload);
  
  //     // Update the UI state
  //     setMonthlyCommission((prev) =>
  //       prev.map((item) =>
  //         item.month_year === commissionData.month_year
  //           ? { ...item, paidStatus: currentStatus }
  //           : item
  //       )
  //     );
  //   } catch (error) {
  //     console.error("Error updating commission status:", error.response ? error.response.data : error.message);
  //   }
  // };
  
  const handleTogglePaidStatus = async (commissionData) => {
    try {
      const currentStatus = commissionData.paidStatus === "Paid" ? "Unpaid" : "Paid";
      const finalAmount = commissionData.total_commission || 0;
  
      // Set payment date if status is marked as "Paid"
      const paymentDate = currentStatus === "Paid" ? dayjs().toISOString() : null;
  
      const payload = {
        manager: selectedManager,
        status: currentStatus,
        amount: finalAmount,
        month: commissionData.month_year,
        payment_date: paymentDate,
      };
  
      await axios.post(`/api/update-commission-status`, payload);
  
      setMonthlyCommission((prev) =>
        prev.map((item) =>
          item.month_year === commissionData.month_year
            ? { ...item, paidStatus: currentStatus, payment_date: paymentDate } // Update payment date in UI
            : item
        )
      );
    } catch (error) {
      console.error("Error updating commission status:", error);
    }
  };
  
  
  
  
  


  
  
  
  useEffect(() => {
    const fetchYearlyCommission = async (managerEmail) => {
      if (!managerEmail) return;
      setLoading(true);
      try {
        const response = await axios.get(`/commission/yearly/${managerEmail}`);
        console.log('Commission data:', response.data);  // Log response here
  
        const aggregatedCommission = response.data.reduce((acc, current) => {
          const { month_year, total_commission } = current;
          const existingEntry = acc.find((item) => item.month_year === month_year);
          if (existingEntry) {
            existingEntry.total_commission += parseFloat(total_commission);
          } else {
            acc.push({
              month_year,
              total_commission: parseFloat(total_commission),
            });
          }
          return acc;
        }, []);
  
        setMonthlyCommission(aggregatedCommission || []);
      } catch (error) {
        console.error("Error fetching yearly commission:", error);
        setMonthlyCommission([]);
      } finally {
        setLoading(false);
      }
    };
  
    if (selectedManager) {
      fetchYearlyCommission(selectedManager);
    }
  }, [selectedManager]);

  
  useEffect(() => {
    const saveUnpaidMonths = async () => {
      try {
        console.log("Saving unpaid months for manager:", selectedManager);
    
        // Filter only months that don't have a paid status before saving them as unpaid
        const unpaidMonths = monthlyCommission.filter(item => item.paidStatus !== 'Paid').map(item => item.month_year);
        
        if (unpaidMonths.length > 0) {
          const response = await axios.post("/api/save-unpaid-entries", {
            manager: selectedManager,
            months: unpaidMonths,
          });
    
          console.log("Unpaid months saved:", response.data);
    
          // Re-fetch commission data after saving unpaid months to ensure correct state
          fetchCommissionPayments();
        }
      } catch (error) {
        console.error("Error saving unpaid months:", error);
      }
    };
    
  
    if (selectedManager) {
      saveUnpaidMonths();
    }
  }, [selectedManager, fetchCommissionPayments]);
  
  
  
  
// Conditionally show the commission table based on user role
const showCommissionTable = () => {
  if (loggedInUser?.role === "Admin") {
    return (
      <div>
        {/* Admins can select any client manager */}
        <div className="mb-4 mt-4">
          <label htmlFor="clientManager" className="block text-sm font-medium text-gray-700">
            Select Client Manager
          </label>
          <select
            id="clientManager"
            name="clientManager"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
            value={selectedManager || ""}
            onChange={handleManagerChange}
          >
            <option value="">Select Manager</option>
            {clientManagers.map((managerData) => (
              <option key={managerData.id} value={managerData.manager}>
                {managerData.manager_first_name || "Unknown"} {managerData.last_name || ""}
              </option>
            ))}
          </select>
        </div>
        {selectedManager && renderCommissionTable()}
      </div>
    );
  } else if (loggedInUser?.role === "Client Manager") {
    // Client Managers automatically see their own commission data
    return selectedManager && renderCommissionTable();
  }
  return null;
};

  // Render the commission table
  const renderCommissionTable = () => {
    return (
      <div className="w-full overflow-hidden rounded-xl border border-gray-200 mb-6 mt-6">
        <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
          <div className="text-lg font-medium leading-6 text-gray-900">Commission</div>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Month
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Commission
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Details
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {monthlyCommission.map((commissionData) => (
                <React.Fragment key={commissionData.month_year}>
                  <tr>
                    <td className="px-6 py-4 text-gray-700">{commissionData.month_year || 'Unknown Month'}</td>
                    <td className="px-6 py-4 text-gray-700">
                      £{isNaN(commissionData.total_commission) ? '0.00' : parseFloat(commissionData.total_commission).toFixed(2)}
                    </td>
                    <td className="px-6 py-4">
  {commissionData.paidStatus === "Paid" ? (
    <span className="text-green-700 bg-green-50 ring-green-600/20 ml-4 px-2 py-1 rounded text-xs font-medium">
      Paid on {dayjs(commissionData.payment_date).format('MMM DD, YYYY')}
    </span>
  ) : (
    <button
      onClick={() => handleTogglePaidStatus(commissionData)}
      className="text-red-700 bg-red-50 ring-red-600/10 ml-4 px-2 py-1 rounded text-xs font-medium"
    >
      Unpaid
    </button>
  )}
</td>

                    <td className="px-6 py-4">
                      <button
                        onClick={() => toggleRowExpanded(commissionData.month_year)}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        {expandedRows[commissionData.month_year] ? "Hide" : "Show"} Details
                      </button>
                    </td>
                  </tr>
                  {expandedRows[commissionData.month_year] && (
  <tr>
    <td colSpan="4" className="px-6 py-4">
      {console.log("Appointments for month:", commissionData.month_year, commissionData.appointments)}
      <AppointmentsSubTable appointments={commissionData.appointments || []} />
    </td>
  </tr>
)}

                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  
  

  const isWholeMonth = (startDate, endDate) => {
    return (
      startDate.date() === 1 && endDate.isSame(startDate.endOf('month'), 'day')
    );
  };


  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }


  
  const generateMonthsForYear = (year = dayjs().year()) => {
    const monthsArray = [];
    const currentDate = dayjs(); // Current date
  
    for (let month = 0; month < 12; month++) {
      const monthStart = dayjs().year(year).month(month).startOf('month').format('YYYY-MM'); // Use 'YYYY-MM' format
      if (monthStart <= currentDate.format('YYYY-MM')) {
        monthsArray.push(monthStart);
      }
    }
  
    return monthsArray;
  };
  
  
  

  useEffect(() => {
    console.log('Client Managers Data:', clientManagers); // Log the entire clientManagers array
  }, [clientManagers]);
  

  useEffect(() => {
    fetchLeaderboardData();
    fetchCommissionPayments(); // Ensure this is called to get the latest payment statuses
  }, [fetchLeaderboardData, fetchCommissionPayments]);
  


   // Helper function to dynamically assign status styles
   const getStatusClass = (status) => {
    return status === "Paid" ? statuses.Paid : statuses.Unpaid;
  };

  return (
    <div className="mx-auto p-4">
      <h1 className="mt-3 text-3xl font-bold tracking-tight text-slate-900 mb-4">Leaderboard</h1>

      <button
  onClick={() => setShowDatePicker((prev) => !prev)}
  className="px-4 py-2 border rounded mb-4"
>
  {dateRange.startDate.format("MMM DD, YYYY")} -{" "}
  {dateRange.endDate.format("MMM DD, YYYY")}
</button>

<Popover
  open={showDatePicker}
  anchorEl={anchorEl}
  onClose={() => setShowDatePicker(false)}
  anchorOrigin={{
    vertical: "top",
    horizontal: "left",
  }}
>
  <div className="p-4">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDateRangePicker
        displayStaticWrapperAs="desktop"
        value={[dateRange.startDate, dateRange.endDate]}
        onChange={handleDateRangeChange}
        slotProps={{
          shortcuts: {
            items: shortcutsItems,
          },
          actionBar: { actions: [] },
        }}
        calendars={2}
      />
    </LocalizationProvider>
  </div>
</Popover>


{loading ? (
  <div className="text-center">
    <p className="text-xl font-semibold">Loading leaderboard...</p>
  </div>
) : (
  <>
    {/* First Row: Connected, First Lesson Complete, Won, Packages */}
    <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8 mt-6">
      {firstRowCards.map(({ title, key, image }) => (
        <li key={title} className="overflow-hidden rounded-xl border border-gray-200">
          <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
            <img alt={title} src={image} className="h-12 w-12 flex-none rounded-lg object-cover" />
            <div className="text-lg font-medium leading-6 text-gray-900">{title}</div>
          </div>
          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
          {clientManagers.map((managerData) => (
    <div key={managerData.manager} className="flex justify-between gap-x-4 py-3">
      <dt className="flex items-center gap-x-2 text-gray-500">
        <img
          src={managerData.profile_image}
          alt={managerData.manager_first_name}
          className="h-6 w-6 rounded-full object-cover"
        />
        {managerData.manager_first_name || managerData.manager}
      </dt>
      <dd className="text-gray-700">
  {key === "lostFirstSession"
    ? (console.log(`Lost First Session count for manager ${managerData.manager}:`, managerData.lostFirstSession), managerData.lostFirstSession)
    : managerData[key] || 0}
</dd>

    </div>
  ))}
          </dl>
        </li>
      ))}
    </ul>

    {/* Second Row: Lost Pre-Connection, Lost First Session, Lost Post Connection */}
    <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8 mt-6">
    {secondRowCards.map(({ title, key, image }) => (
    <li key={title} className="overflow-hidden rounded-xl border border-gray-200">
      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
        <img alt={title} src={image} className="h-12 w-12 flex-none rounded-lg object-cover" />
        <div className="text-lg font-medium leading-6 text-gray-900">{title}</div>
      </div>
      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
  {clientManagers.map((managerData) => (
    <div key={managerData.manager} className="flex justify-between gap-x-4 py-3">
      <dt className="flex items-center gap-x-2 text-gray-500">
        <img
          src={managerData.profile_image}
          alt={managerData.manager_first_name}
          className="h-6 w-6 rounded-full object-cover"
        />
        {managerData.manager_first_name || managerData.manager}
      </dt>
      <dd className="text-gray-700">
        {key === "lostFirstSession"
          ? (console.log(`Lost First Session count for manager ${managerData.manager}:`, managerData.lostFirstSession), managerData.lostFirstSession)
          : managerData[key] || 0}
      </dd>
    </div>
  ))}
</dl>

    </li>
  ))}

    </ul>
  </>
)}

{showCommissionTable()}


    </div>

    
  );
};

const AppointmentsSubTable = ({ appointments = [] }) => {
  console.log("Appointments received by AppointmentsSubTable:", appointments); // Debugging log

  if (appointments.length === 0) {
    return <div>No appointments available for this month. If there is commission, then it's calculated from packages sold.</div>;
  }

  return (
    <table className="min-w-full divide-y divide-gray-200 bg-gray-50">
      <thead>
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Appointment ID
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Service ID
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Start
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Finish
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Duration (hours)
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Total Commission (£)
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {appointments.map((appointment) => (
          <tr key={appointment.appointment_id}>
            <td className="px-6 py-4">{appointment.appointment_id}</td>
            <td className="px-6 py-4">{appointment.service_id}</td>
            <td className="px-6 py-4">{new Date(appointment.start).toLocaleString()}</td>
            <td className="px-6 py-4">{new Date(appointment.finish).toLocaleString()}</td>
            <td className="px-6 py-4">{appointment.duration_hours}</td>
            <td className="px-6 py-4">{parseFloat(appointment.commission).toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};




export default Leaderboard;