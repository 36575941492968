import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Papa from 'papaparse'; // Import PapaParse



const ClientList = () => {
  const [clients, setClients] = useState([]);
  const [newClientsCount, setNewClientsCount] = useState(0);
  const [filter, setFilter] = useState('all'); // 'all', 'new', 'existing'
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  useEffect(() => {
    fetchClients();
    checkNewClients();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axios.get('/api/clients');
      const clientsWithEnquiries = await Promise.all(
        response.data.map(async (client) => {
          const enquiriesResponse = await axios.get(`/api/clients/${client.id}/enquiries`);
          return {
            ...client,
            enquiriesCount: enquiriesResponse.data.length,
          };
        })
      );
      setClients(clientsWithEnquiries);
    } catch (error) {
      console.error('Error fetching clients:', error.message);
    }
  };

  const checkNewClients = async () => {
    try {
      const response = await axios.get('/api/tutorcruncher/check-new-clients');
      setNewClientsCount(response.data.newClientsCount);
    } catch (error) {
      console.error('Error checking Leads from TutorCruncher:', error.message);
    }
  };


  const handleFileUpload = (event) => {
    const file = event.target.files[0];
  
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (result) => {
          handleCSVData(result.data); // Process the parsed data
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
        }
      });
    }
  };

  const handleCSVData = async (data) => {
    try {
      const response = await axios.post('/api/clients/update-from-csv', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log(response.data.message);
      fetchClients(); // Refresh the client list after update
    } catch (error) {
      console.error('Error updating clients from CSV:', error.message);
    }
  };
  
  

  const addNewClients = async () => {
    try {
      const response = await axios.post('/api/tutorcruncher/add-new-clients');
      console.log(response.data.message);
      fetchClients(); // Refresh the client list after updating the database
      setNewClientsCount(0); // Reset the count after adding
    } catch (error) {
      console.error('Error adding Leads from TutorCruncher:', error.message);
    }
  };

  

  const handleDeleteClient = async (clientId) => {
    try {
      await axios.delete(`/api/clients/${clientId}`);
      setClients(clients.filter((client) => client.id !== clientId));
    } catch (error) {
      console.error('Error deleting client:', error.message);
    }
  };

  // Handle filtering by search and category (new/existing)
const filteredClients = clients
.filter((client) => {
  if (filter === 'new') {
    return !client.tutorcruncher_id;
  }
  if (filter === 'existing') {
    return client.tutorcruncher_id;
  }
  return true; // 'all'
})
.filter((client) => {
  const firstName = client.first_name ? client.first_name.toLowerCase() : '';
  const lastName = client.last_name ? client.last_name.toLowerCase() : '';
  const email = client.email ? client.email.toLowerCase() : '';

  return (
    firstName.includes(searchTerm.toLowerCase()) ||
    lastName.includes(searchTerm.toLowerCase()) ||
    email.includes(searchTerm.toLowerCase())
  );
});

// Pagination logic
const indexOfLastRow = currentPage * rowsPerPage;
const indexOfFirstRow = indexOfLastRow - rowsPerPage;
const currentRows = filteredClients.slice(indexOfFirstRow, indexOfLastRow);

const paginate = (pageNumber) => setCurrentPage(pageNumber);


  return (
    <div className="">
      <div className="flex justify-between items-center mb-4">
      <h1 className="mt-3 text-3xl font-bold tracking-tight text-slate-900 mb-4">
          Clients
        </h1>
        {/* <input
          type="file"
          accept=".csv"
          onChange={handleFileUpload}
          className="mb-2 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        /> */}
      </div>

      <div className="mb-4 flex items-center">
        <input
          type="text"
          placeholder="Search clients..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="mr-4 px-4 py-2 border rounded-md focus:outline-none"
        />
        <button
          onClick={() => setFilter('all')}
          className={`mr-4 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ${
            filter === 'all'
              ? 'bg-blue-500 text-white'
              : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
          }`}
        >
          All Clients
        </button>
        <button
          onClick={() => setFilter('new')}
          className={`mr-4 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ${
            filter === 'new'
              ? 'bg-blue-500 text-white'
              : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
          }`}
        >
          Leads
        </button>
        <button
          onClick={() => setFilter('existing')}
          className={`mr-4 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ${
            filter === 'existing'
              ? 'bg-blue-500 text-white'
              : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
          }`}
        >
          Clients
        </button>
      </div>

      <table className="min-w-full bg-white border border-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              First Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Last Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Email
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Phone
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Enquiries / Jobs
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              TutorCruncher ID
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Client Manager
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {currentRows.map((client) => (
            <tr key={client.id}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {client.first_name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {client.last_name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {client.email}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {client.phone}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {client.enquiriesCount || 0}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {client.tutorcruncher_id ? client.tutorcruncher_id : 'Not on TutorCruncher'}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {client.client_manager}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <Link
                  to={`/clients/${client.id}`}
                  className="text-indigo-600 hover:text-indigo-900 mr-4"
                >
                  Client Details
                </Link>
                
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 mx-1 bg-gray-200 rounded-md disabled:opacity-50"
        >
          Previous
        </button>
        <span className="px-4 py-2 mx-1">{currentPage}</span>
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={indexOfLastRow >= filteredClients.length}
          className="px-4 py-2 mx-1 bg-gray-200 rounded-md disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ClientList;
