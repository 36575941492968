import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import SalesBookDialog from "./SalesBookDialog";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  BellIcon,
  ChevronDownIcon,
  CheckCircleIcon,
  UserCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { Box, Typography, Select, MenuItem } from "@mui/material";
import { DocumentTextIcon, TrashIcon } from "@heroicons/react/24/solid"; // Replace the pencil icon

import Modal from "@mui/material/Modal";
import { PencilIcon } from "@heroicons/react/24/outline"; // Import a suitable icon


import Popover from "@mui/material/Popover";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import the UTC plugin
import timezone from "dayjs/plugin/timezone"; // Import the timezone plugin

// Use the plugins with dayjs
dayjs.extend(utc);
dayjs.extend(timezone);

// Custom header component for dropdown filters below headers
const CustomHeaderWithDropdown = ({
  label,
  options,
  onFilterChange,
  filterValue,
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
      <span>{label}</span>
      <Select
        value={filterValue || ""}
        onChange={(e) => onFilterChange(e.target.value)}
        displayEmpty
        variant="standard"
        sx={{ fontSize: "0.875rem", minWidth: 120 }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

const SalesBook = () => {
  const [clients, setClients] = useState([]);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null); // To track which enquiry is being noted
  const [notes, setNotes] = useState([]); // Ensures notes is always an array
  const [isNotesDialogOpen, setIsNotesDialogOpen] = useState(false); // To control the open/close state of the notes dialog
  const [selectionModel, setSelectionModel] = useState([]); // Track selected rows
  const [deletedClients, setDeletedClients] = useState([]); // Store temporarily deleted clients
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const [selectedClient, setSelectedClient] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [tab, setTab] = useState("won"); // 'won', 'lost', or 'connected'
  const [editingRow, setEditingRow] = useState(null); // Track which row is being edited
  const [editedData, setEditedData] = useState({}); // Store edited data
  const [showAllColumns, setShowAllColumns] = useState(false); // Control additional columns visibility
  const [clientManagers, setClientManagers] = useState([]); // Store unique client managers
  const [loading, setLoading] = useState(false); // Manage loading state
  const [filteredClients, setFilteredClients] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState(null); // Logged in user info
  const [showRecentUpdates, setShowRecentUpdates] = useState(false); // New filter state
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [jobStatusFilter, setJobStatusFilter] = useState(""); // Job Status filter state
  const [firstLessonPlannedFilter, setFirstLessonPlannedFilter] = useState(""); // First Lesson Planned filter state
  const [milestoneFilter, setMilestoneFilter] = useState(""); // Milestone filter state
  const [clientManagerFilter, setClientManagerFilter] = useState(
    loggedInUser?.role === "Client Manager" ? loggedInUser.firstName : ""
  );
  
  

  const [firstLessonPlannedMap, setFirstLessonPlannedMap] = useState({});

  const [sortModel, setSortModel] = useState([
    {
      field: "created_on_tc_at",
      sort: "desc", // 'desc' for newest to oldest
    },
  ]);

  

  // Extract unique job statuses from the clients data
  const uniqueJobStatuses = useMemo(() => {
    const statuses = clients.map((client) => client.job_status);
    return [...new Set(statuses)]; // Get unique statuses
  }, [clients]);

  
  const handleBulkDelete = async () => {
    if (tab !== "lost") {
      toast.error("Deletion is only allowed in the 'lost' tab.");
      return;
    }
  
    if (rowSelectionModel.length === 0) {
      toast.warning("No enquiries selected for deletion.");
      return;
    }
  
    const enquiryIdsToDelete = Array.isArray(rowSelectionModel)
    ? rowSelectionModel
    : [rowSelectionModel]; // Make sure this is always an array

    console.log("Enquiry IDs to delete:", enquiryIdsToDelete); // Check if IDs are integers

  
    // Store the complete enquiry object instead of just id and salesbook_status
    const deletedEnquiries = clients
      .filter((enquiry) => enquiryIdsToDelete.includes(enquiry.id))
      .map((enquiry) => ({
        ...enquiry, // Ensure all properties are copied here
      }));
  
    console.log("Storing deleted enquiries for undo:", deletedEnquiries);
  
    // Remove the deleted enquiries from the UI immediately
    setClients((prevClients) =>
      prevClients.filter((enquiry) => !enquiryIdsToDelete.includes(enquiry.id))
    );
  
    // Track if the user clicks "Undo"
    let undoClicked = false;
  
    // Set up a timer for the backend deletion
    const timerId = setTimeout(async () => {
      if (!undoClicked) {
        // Proceed to delete the enquiries in the backend if undo was not clicked
        try {
          const response = await axios.put(
            "https://portal.tutorchase.com/api/enquiries/bulk-delete",
            { enquiryIds: enquiryIdsToDelete }, // Send only the enquiryIds in the request body
            { headers: { 'Content-Type': 'application/json' } }
          );
          
  
          console.log("Bulk delete response:", response.data);
          toast.success("Enquiries permanently deleted.", {
            position: "bottom-right",
            autoClose: 3000,
          });
        } catch (error) {
          console.error("Error bulk deleting enquiries:", error.message);
          toast.error("Failed to delete selected enquiries.");
        }
      }
    }, 10000); // 10-second delay
  
    const toastId = toast.success("Enquiries deleted. Click Undo to restore.", {
      position: "bottom-right",
      autoClose: 10000, // Matches the timer
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      onClose: () => {
        // This function runs when the toast times out
        if (!undoClicked) {
          // Do nothing here since the backend deletion is handled by setTimeout
        }
      },
    });
  
    // Add Undo Button to Toast
    const undoButton = (
      <button
        onClick={() => {
          clearTimeout(timerId); // Cancel the backend delete
          undoClicked = true; // Mark that undo was clicked
          setClients((prevClients) => [...prevClients, ...deletedEnquiries]); // Restore the complete enquiries
          toast.dismiss(toastId); // Dismiss the current toast
        }}
        className="px-4 py-1 text-white bg-blue-500 rounded"
      >
        Undo
      </button>
    );
  
    toast.update(toastId, { render: undoButton });
  };
  
  
  
  

  // Handle filters for Job Status, First Lesson Planned, and Milestones
  const handleJobStatusFilterChange = (newStatus) => {
    setJobStatusFilter(newStatus);
    applyFilters(newStatus, firstLessonPlannedFilter, milestoneFilter);
  };

  const handleFirstLessonPlannedFilterChange = (newValue) => {
    setFirstLessonPlannedFilter(newValue);
    applyFilters(jobStatusFilter, newValue, milestoneFilter);
  };

  const handleMilestoneFilterChange = (newMilestone) => {
    setMilestoneFilter(newMilestone);
    applyFilters(jobStatusFilter, firstLessonPlannedFilter, newMilestone);
  };

  const applyFilters = (jobStatus, firstLessonPlanned, milestone) => {
    let filtered = clients;
  
    if (jobStatus && jobStatus !== "") {
      filtered = filtered.filter((client) => client.job_status === jobStatus);
    }
  
    if (firstLessonPlanned !== "") {
      filtered = filtered.filter(
        (client) =>
          (firstLessonPlanned === "Yes" && client.first_lesson_planned) ||
          (firstLessonPlanned === "No" && !client.first_lesson_planned)
      );
    }
  
    if (milestone) {
      filtered = filtered.filter((client) => client.stage === milestone);
    }
  
    if (loggedInUser && loggedInUser.role !== "Admin" && loggedInUser.role !== "Assistant") {
      filtered = filtered.filter(
        (client) => client.clientManagerEmail === loggedInUser.email
      );
    }
  
    setFilteredClients(filtered);
  };
  
  
  
  const NotesDialog = ({ isOpen, closeModal, enquiryId, saveNote, notes, deleteNote, updateNote }) => {
    const [newNote, setNewNote] = useState("");
    const [editMode, setEditMode] = useState(null); // To track the note being edited
    const [editedNote, setEditedNote] = useState(""); // The note being edited
  
    const handleSave = () => {
      if (editMode !== null) {
        updateNote(editMode, editedNote); // Save the edited note
        setEditMode(null); // Exit edit mode after saving
        setEditedNote(""); // Clear the edited note
      } else {
        saveNote(enquiryId, newNote); // Save a new note
        setNewNote(""); // Clear the new note
      }
    };
  
    const handleEditClick = (note) => {
      setEditMode(note.id); // Set the note ID in edit mode
      setEditedNote(note.note); // Set the current note's text in the editor
    };
  
    const handleDeleteClick = (noteId) => {
      if (window.confirm("Are you sure you want to delete this note?")) {
        deleteNote(enquiryId, noteId);
      }
    };
  
    return (
      <Modal open={isOpen} onClose={closeModal}>
        <div className="flex items-center justify-center min-h-screen">
          <div className="relative w-full max-w-lg p-6 bg-white rounded shadow-md">
            <XMarkIcon
              className="w-6 h-6 text-gray-500 absolute top-2 right-2 cursor-pointer"
              onClick={closeModal}
            />
            <h2 className="text-lg font-semibold mb-4">Manage Notes</h2>
  
            <div className="mb-4 max-h-64 overflow-y-auto">
              {Array.isArray(notes) && notes.length > 0 ? (
                notes.map((note) => (
                  <div key={note.id} className="mb-2 text-sm text-gray-500 flex justify-between items-start">
                    <div>
                      {editMode === note.id ? (
                        <textarea
                          value={editedNote}
                          onChange={(e) => setEditedNote(e.target.value)}
                          className="w-full p-2 border rounded"
                          rows={2}
                        />
                      ) : (
                        <p>{note.note}</p>
                      )}
                      <small>
                        {note.client_manager} - {new Date(note.date_added).toLocaleString()}
                      </small>
                    </div>
                    <div className="flex space-x-2 ml-4">
                      {editMode === note.id ? (
                        <button
                          onClick={handleSave}
                          className="text-blue-500 hover:text-blue-700"
                        >
                          Save
                        </button>
                      ) : (
                        <>
                          <PencilIcon
                            className="w-5 h-5 text-gray-500 hover:text-gray-700 cursor-pointer"
                            onClick={() => handleEditClick(note)}
                          />
                          <TrashIcon
                            className="w-5 h-5 text-red-500 hover:text-red-700 cursor-pointer"
                            onClick={() => handleDeleteClick(note.id)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <p>No notes available.</p>
              )}
            </div>
  
            <textarea
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              className="w-full p-2 border rounded mb-4"
              rows={3}
              placeholder="Add a new note..."
            />
  
            <button
              onClick={handleSave}
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              {editMode !== null ? "Update Note" : "Add Note"}
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  
  
  const handleDeleteNote = async (enquiryId, noteId) => {
    try {
      await axios.delete(`/api/notes/${noteId}`);
  
      // Refetch notes to ensure the count is accurate
      await handleOpenNotesDialog(enquiryId);
  
      toast.success("Note deleted successfully!");
    } catch (error) {
      console.error("Error deleting note:", error.message);
      toast.error("Failed to delete note.");
    }
  };
  
  
  
  const handleUpdateNote = async (noteId, updatedNote) => {
    try {
      await axios.put(`/api/notes/${noteId}`, { note: updatedNote });
      await handleOpenNotesDialog(selectedEnquiry); // Refetch notes after update
      toast.success("Note updated successfully!"); // Display success toast for update
    } catch (error) {
      console.error("Error updating note:", error.message);
      toast.error("Failed to update the note!"); // Display error toast for update
    }
  };
  
  
  
  const handleOpenNotesDialog = async (enquiryId) => {
    try {
      const response = await axios.get(`/api/enquiries/${enquiryId}/notes`);
      const notesData = response.data;
  
      setSelectedEnquiry(enquiryId);
      setNotes(notesData);
  
      // Update the note count based on the actual number of notes fetched
      setClients((prevClients) =>
        prevClients.map((client) =>
          client.id === enquiryId
            ? { ...client, notes_count: notesData.length }
            : client
        )
      );
  
      setIsNotesDialogOpen(true);
    } catch (error) {
      console.error("Error fetching notes:", error.message);
    }
  };
  

  
  const handleSaveNote = async (enquiryId, note) => {
    try {
      await axios.post(`/api/enquiries/${enquiryId}/notes`, {
        client_manager: loggedInUser.email,
        note,
      });
  
      // Refetch notes to update the count
      await handleOpenNotesDialog(enquiryId);
  
      // Close the dialog after successfully adding the note
      setIsNotesDialogOpen(false);
  
      // Display a success toast notification
      toast.success("Note successfully added!");
    } catch (error) {
      console.error("Error saving note:", error.message);
      toast.error("Failed to add note.");
    }
  };
  
  
  
  
  

  // Milestone options
  const milestoneOptions = [
    { value: "", label: "All Milestones" },
    { value: "No Lessons Complete", label: "No Lessons Complete" },
    { value: "First Lesson Complete", label: "First Lesson Complete" },
    { value: "2 Lessons Complete", label: "2 Lessons Complete" },
    { value: "3 Lessons Complete", label: "3 Lessons Complete" },
    { value: "5 Lessons Complete", label: "5 Lessons Complete" },
    { value: "10 Lessons Complete", label: "10 Lessons Complete" },
    { value: "20 Lessons Complete", label: "20 Lessons Complete" },
  ];

  const firstLessonPlannedOptions = [
    { value: "", label: "All" },
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const jobStatusOptions = [
    { value: "", label: "All" },
    ...uniqueJobStatuses.map((status) => ({
      value: status,
      label: status,
    })),
  ];

  useEffect(() => {
    setFilteredClients(clients); // By default, show all clients when no milestone is selected
  }, [clients]);

  useEffect(() => {
    console.log("Selection Model has been updated:", selectionModel);
  }, [selectionModel]); // This logs the selected rows after each update
  
  

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [dateRange, setDateRange] = useState({
    startDate: dayjs().startOf("year"), // Start of the current year
    endDate: dayjs().endOf("year"), // End of the current year
  });

  const [filterModel, setFilterModel] = useState({
    items: [
      {
        columnField: 'created_on_tc_at',  // Ensure this field is correctly set
        operatorValue: 'contains',
        value: '',
      },
    ],
  });
  

  // const handleFilterModelChange = (newModel) => {
  //   if (JSON.stringify(newModel) !== JSON.stringify(filterModel)) {
  //     setFilterModel(newModel); // Only update when there's an actual change
  //   }
  // };

  const handleFilterModelChange = (newModel) => {
    // Check if the new filter model is different from the current one
    if (JSON.stringify(newModel) !== JSON.stringify(filterModel)) {
      setFilterModel(newModel); // Update only when there's an actual change
    }
  };
  
  

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    fetchEnquiries();
  }, [dateRange]); // Fetch enquiries whenever the date range changes

  const shortcutsItems = [
    {
      label: "Today", // Changed label from "Last 7 Days" to "Today"
      getValue: () => {
        const today = dayjs();
        return [today.startOf("day"), today.endOf("day")]; // Set start and end to today
      },
    },
    {
      label: "This Week",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("week"), today.endOf("week")];
      },
    },
    {
      label: "Last Week",
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, "day");
        return [prevWeek.startOf("week"), prevWeek.endOf("week")];
      },
    },
    
    {
      label: "Last Month",
      getValue: () => {
        const today = dayjs();
        const lastMonth = today.subtract(1, "month");
        return [lastMonth.startOf("month"), lastMonth.endOf("month")];
      },
    },
    {
      label: "Current Month",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("month"), today.endOf("month")];
      },
    },
    {
      label: "This Year",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("year"), today.endOf("year")];
      },
    },
    {
      label: "Last Year",
      getValue: () => {
        const today = dayjs();
        const lastYear = today.subtract(1, "year");
        return [lastYear.startOf("year"), lastYear.endOf("year")];
      },
    },
    { label: "Reset", getValue: () => [null, null] },
  ];

  
  const uniqueClientManagers = useMemo(() => {
    const managers = clients
      .map((client) => client.clientManagerName) // Extract client manager names
      .filter(Boolean); // Remove any falsy values (e.g., undefined)
  
    return [...new Set(managers)]; // Remove duplicates by converting to a Set and back to an array
  }, [clients]);
  

  useEffect(() => {
    const fetchLoggedInUser = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found, redirecting to login");
          return;
        }
  
        const response = await axios.get("/api/current-user", {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        setLoggedInUser(response.data);
        console.log("Logged in user:", response.data);
      } catch (error) {
        console.error("Error fetching logged-in user:", error);
      }
    };
  
    fetchLoggedInUser();
  }, []);
  
  useEffect(() => {
    if (loggedInUser) {
      fetchEnquiries();  // Only fetch enquiries when loggedInUser is ready
    }
  }, [loggedInUser, tab, showRecentUpdates]);



  
  // const fetchEnquiries = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.get("/api/salesbook", {
  //       params: {
  //         startDate: dateRange.startDate.toISOString(),
  //         endDate: dateRange.endDate.toISOString(),
  //         tab: tab,
  //         // manager: selectedManager,
  //         // role: loggedInUser?.role,
  //         // email: loggedInUser?.email,
  //         showRecentUpdates: showRecentUpdates,
  //       },
  //     });

  //     const enquiriesWithProfit = await Promise.all(
  //       response.data.enquiries.map(async (enquiry) => {
  //         if (tab === "connected") {
  //           await checkAndUpdateFirstLessonPlanned(enquiry.job_id);
  //         }
  //         // Ensure you're fetching the updated first_lesson_planned field from the backend
  //         return {
  //           ...enquiry,
  //           first_lesson_planned: enquiry.first_lesson_planned || false,
  //           in_progress_date: enquiry.in_progress_date,
  //           package_profit: parseFloat(enquiry.package_profit).toFixed(2),
  //           calculated_profit: parseFloat(enquiry.calculated_profit).toFixed(2),
  //         };
  //       })
  //     );

  //     setClients(enquiriesWithProfit);
  //     setClientManagers(response.data.clientManagers);
  //   } catch (error) {
  //     console.error("Error fetching salesbook data:", error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  
  const fetchEnquiries = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/salesbook", {
        params: {
          startDate: dateRange.startDate.toISOString(),
          endDate: dateRange.endDate.toISOString(),
          tab: tab,
          showRecentUpdates: showRecentUpdates,
        },
      });
  
      // Process enquiries and calculate profit values
      const enquiriesWithProfit = await Promise.all(
        response.data.enquiries.map(async (enquiry) => {
          if (tab === "connected") {
            await checkAndUpdateFirstLessonPlanned(enquiry.job_id);
          }
          return {
            ...enquiry,
            first_lesson_planned: enquiry.first_lesson_planned || false,
            in_progress_date: enquiry.in_progress_date,
            package_profit: parseFloat(enquiry.package_profit).toFixed(2),
            calculated_profit: parseFloat(enquiry.calculated_profit).toFixed(2),
          };
        })
      );
  
      // Filter out "Deleted" enquiries
      let filteredEnquiries = enquiriesWithProfit.filter(
        (enquiry) => enquiry.salesbook_status !== 'Deleted'
      );
  
      // Further filter based on logged-in user's role if necessary
      if (loggedInUser && loggedInUser.role === "Client Manager") {
        filteredEnquiries = filteredEnquiries.filter(
          (enquiry) => enquiry.clientManagerEmail === loggedInUser.email
        );
      }
  
      // Update state with the filtered enquiries
      setClients(filteredEnquiries);
    } catch (error) {
      console.error("Error fetching salesbook data:", error.message);
    } finally {
      setLoading(false);
    }
  };
  
  
  
  const handleClientManagerFilterChange = (clientManagerName) => {
    setClientManagerFilter(clientManagerName);
    applyFilters(jobStatusFilter, firstLessonPlannedFilter, milestoneFilter);
  };
  
  
  
  const clientManagerOptions = uniqueClientManagers.map((manager) => ({
    value: manager,
    label: manager,
  }));
  
  
  // Conditionally render the dropdown if the user is Admin or Assistant
  const renderClientManagerFilter = () => {
    if (loggedInUser?.role === "Admin" || loggedInUser?.role === "Assistant") {
      return (
        <CustomHeaderWithDropdown
          label="Client Manager"
          options={clientManagerOptions}
          onFilterChange={handleClientManagerFilterChange}
          filterValue={clientManagerFilter}
        />
      );
    }
    return null;
  };
  
  
  

  const twelveHoursAgo = new Date(Date.now() - 12 * 60 * 60 * 1000);


  useEffect(() => {
    console.log("Client Managers List:", clientManagers);
  }, [clientManagers]);
  

  

  const checkAndUpdateFirstLessonPlanned = async (jobId) => {
    try {
      // Fetch the first lesson planned status from your local database
      const response = await axios.get(
        `/api/enquiries/${jobId}/first-lesson-planned`
      );

      // Update the local state or handle accordingly
      console.log(
        `First lesson planned status for job ${jobId}:`,
        response.data.firstLessonPlanned
      );
    } catch (error) {
      console.error(
        `Error fetching first lesson for job ${jobId}:`,
        error.message
      );
    }
  };


  const handleDateRangeChange = (newValue) => {
    if (newValue && newValue[0] && newValue[1]) {
      setDateRange({
        startDate: newValue[0].startOf("day"),
        endDate: newValue[1].endOf("day"),
      });
    }
  };

  const openDatePicker = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeDatePicker = () => {
    setAnchorEl(null);
  };

  const handleShortcutClick = (shortcut) => {
    let newRange;
    switch (shortcut) {
      case "This Week":
        newRange = [dayjs().startOf("week"), dayjs().endOf("week")];
        break;
      case "Last Week":
        newRange = [
          dayjs().subtract(1, "week").startOf("week"),
          dayjs().subtract(1, "week").endOf("week"),
        ];
        break;
      case "This Month":
        newRange = [dayjs().startOf("month"), dayjs().endOf("month")];
        break;
      case "Last Month":
        newRange = [
          dayjs().subtract(1, "month").startOf("month"),
          dayjs().subtract(1, "month").endOf("month"),
        ];
        break;
      default:
        return;
    }
    setDateRange(newRange);
    closeDatePicker(); // Close the picker after selecting a shortcut
  };

  const columns = [
    {
      field: "notes",
      headerName: "Notes",
      width: 100,
      renderCell: (params) => (
        <button
          className="flex items-center space-x-2 text-indigo-600 hover:text-indigo-900 mt-2"
          onClick={() => handleOpenNotesDialog(params.row.id)}
        >
          <span
            className={`inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10`}
          >
            <DocumentTextIcon className="w-5 h-5" /> {params.row.notes_count || 0}
          </span>
        </button>
      ),
    }
    ,
    {
      field: "markAsSeen",
      headerName: "Has Updates",
      width: 180,
      renderCell: (params) => (
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
          {!params.row.seen && (
            <button
              onClick={() => markAsSeen(params.row.id)}
              className="inline-flex items-center px-2 py-1 text-xs font-medium text-green-700 bg-green-50 rounded-md hover:bg-green-100"
            >
              <CheckCircleIcon className="h-4 w-4 mr-1" />
              Mark as Seen
            </button>
          )}
        </td>
      ),
    },
    {
      field: "job_id",
      headerName: "Job ID",
      width: 150,
      renderCell: (params) => (
        <Link
          to={`https://app.tutorchase.com/cal/service/${params.row.job_id}`}
          className="text-blue-600 hover:underline"
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "created_on_tc_at",
      headerName: "Created on TC",
      width: 150,
      renderCell: (params) => {
        const dateInLondon = dayjs(params.value)
          .tz("Europe/London")
          .format("DD/MM/YYYY HH:mm");
        return <span>{dateInLondon}</span>;
      },
    },
    // {
    //   field: "in_progress_date",
    //   headerName: "Connected",
    //   width: 150,
    //   renderCell: (params) => {
    //     const dateInLondon = params.value
    //       ? dayjs(params.value).tz("Europe/London").format("DD/MM/YYYY HH:mm")
    //       : "N/A";
    //     return <span>{dateInLondon}</span>;
    //   },
    // },

    {
      field: "clientManagerName",
      headerName: "Client Manager",
      width: 150, // Adjust the width as needed
      renderCell: (params) => (
        <span>{params.value ? params.value : "Unknown"}</span> // Display 'Unknown' if no client manager is assigned
      ),
    },
    // {
    //   field: "clientManagerEmail",
    //   headerName: "Client Manager Email",
    //   width: 250, // Adjust the width as needed
    //   renderCell: (params) => (
    //     <span>{params.value ? params.value : "Unknown"}</span> // Display 'Unknown' if no client manager is assigned
    //   ),
    // },
    {
      field: "full_name",
      headerName: "Full Name",
      width: 150,
      renderCell: (params) => (
        <Link
          to={`/clients/${params.row.client_id}`}
          className="text-blue-600 hover:underline"
        >
          {params.value}
        </Link>
      ),
    },

    {
      field: "stage",
      headerName: "Milestone",
      width: 180,
      renderHeader: () => (
        <CustomHeaderWithDropdown
          label="Milestone"
          options={milestoneOptions}
          onFilterChange={handleMilestoneFilterChange}
          filterValue={milestoneFilter}
        />
      ),
    },

    {
      field: "job_status",
      headerName: "Job Status",
      width: 150,
      renderHeader: () => (
        <CustomHeaderWithDropdown
          label="Job Status"
          options={jobStatusOptions}
          onFilterChange={handleJobStatusFilterChange}
          filterValue={jobStatusFilter}
        />
      ),
    },
    { field: "charge_rate", headerName: "Client Cost £", width: 120 },
    { field: "tutor_rate", headerName: "Tutor Pay £", width: 120 },
    {
      field: "totallessonhourscomplete",
      headerName: "Complete Hours",
      width: 150,
    },
    { field: "profit", headerName: "Lessons Profit £", width: 140 },
    { field: "package_profit", headerName: "Package Profit £", width: 160 },
    { field: "calculated_profit", headerName: "Total Profit £", width: 120 },
    {
      field: "packageExists",
      headerName: "Package",
      width: 120,
      renderCell: (params) => {
        const packageCount = params.row.package_count || 0; // Get the package count, default to 0 if undefined
        const hasPackages = packageCount > 0; // Check if packages exist
        return (
          <span
            className={`inline-flex items-center px-2 py-1 rounded-md text-xs font-medium ${
              hasPackages ? "bg-green-100 text-green-800" : "bg-gray-100 text-gray-800"
            }`}
          >
            {hasPackages ? `Yes (${packageCount})` : "No"}
          </span>
        );
      },
    },
    

    { field: "tutorcruncher_id", headerName: "Client TC ID", width: 150 },
    {
      field: "predicted_lessons",
      headerName: "Predicted Lessons",
      width: 150,
      renderCell: (params) =>
        editingRow === params.row.id ? (
          <input
            type="number"
            value={
              editedData[params.row.id]?.predicted_lessons ||
              params.row.predicted_lessons
            }
            onChange={(e) =>
              handleInputChange(e, "predicted_lessons", params.row.id)
            }
            className="w-full px-2 py-1 border rounded"
          />
        ) : (
          params.row.predicted_lessons || "N/A"
        ),
    },
    {
      field: "totalLessonsComplete",
      headerName: "Total Lessons Complete",
      width: 150,
    },
    {
      field: "predictionAccuracy",
      headerName: "Prediction Accuracy",
      width: 180,
      renderCell: (params) => (
        <span
          className={`inline-flex items-center px-2 py-1 rounded-md text-xs font-medium ${
            params.row.predicted_lessons === null
              ? "bg-gray-100 text-gray-800" // Gray for "No Prediction"
              : params.row.totalLessonsComplete >= params.row.predicted_lessons
              ? "bg-green-100 text-green-800" // Green for "Accurate Prediction"
              : "bg-red-100 text-red-800" // Red for "Inaccurate Prediction"
          }`}
        >
          {params.row.predicted_lessons === null
            ? "No Prediction"
            : calculatePredictionAccuracy(
                params.row.predicted_lessons,
                params.row.totalLessonsComplete
              )}
        </span>
      ),
    },

    { field: "avglessonlength", headerName: "Avg Lesson Length", width: 150 },
    { field: "revenue", headerName: "Revenue £", width: 120 },
    //{ field: "package", headerName: "Package", width: 120 },
    { field: "cost", headerName: "Cost £", width: 120 },
    ...(showAllColumns
      ? [
          { field: "email", headerName: "Email", width: 200 },
          { field: "phone", headerName: "Phone", width: 150 },
          { field: "country", headerName: "Country", width: 150 },
          { field: "customer_type", headerName: "Customer Type", width: 150 },
          { field: "form_host", headerName: "Form Host", width: 150 },
          { field: "gclid", headerName: "GCLID", width: 150 },
          { field: "lead_source", headerName: "Lead Source", width: 150 },
          {
            field: "how_did_they_hear_about_us",
            headerName: "How Did They Hear About Us",
            width: 200,
          },
          { field: "approx_hours", headerName: "Approx Hours", width: 150 },
          { field: "school_year", headerName: "School Year", width: 150 },
          { field: "budget", headerName: "Budget", width: 120 },
          { field: "hascontractor", headerName: "Has Tutor", width: 150 },
          {
            field: "contractor_name",
            headerName: "Tutor",
            width: 150,
            renderCell: (params) => (
              <span>{params.value || 'No Contractor Assigned'}</span>
            ),
          },
          { field: "subject", headerName: "Subject", width: 150 },
          // { field: "vat", headerName: "VAT", width: 100 },
          // { field: "comms", headerName: "Comms", width: 120 },
          // { field: "formId", headerName: "Form ID", width: 150 },
          // { field: "landPage", headerName: "Landing Page", width: 200 },
        ]
      : []), // Add additional columns conditionally

    {
      field: "salesbook_status",
      headerName: "Salesbook Status",
      width: 150,
      renderCell: (params) => (
        <select
          value={params.value || "N/A"}
          onChange={(e) =>
            handleInputChange(e, "salesbook_status", params.row.id)
          }
          className="w-full px-2 py-1 border rounded"
        >
          <option value="N/A">N/A</option>
          <option value="Connected">Connected</option>
          <option value="Won">Won</option>
          <option value="Lost Pre-Tutor">Lost Pre-Tutor</option>
          <option value="Lost Post-Tutor">Lost Post-Tutor</option>
        </select>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: (params) =>
        editingRow === params.row.id ? (
          <>
            <button
              onClick={() =>
                saveInlineEdit(params.row.client_id, params.row.id)
              }
              className="text-green-600 hover:text-green-900 mr-2"
            >
              Save
            </button>
            <button
              onClick={cancelInlineEdit}
              className="text-red-600 hover:text-red-900"
            >
              Cancel
            </button>
          </>
        ) : (
          <button
            onClick={() => setEditingRow(params.row.id)}
            className="text-indigo-600 hover:text-indigo-900"
          >
            Edit Predicted Lessons
          </button>
        ),
    },
  ];

  const allColumns = [...columns]; // Clone the base columns

  if (tab === "connected") {
    const firstLessonPlannedColumn = {
      field: "first_lesson_planned",
      headerName: "First Lesson Planned",
      width: 180,
      renderHeader: () => (
        <CustomHeaderWithDropdown
          label="First Lesson Planned"
          options={firstLessonPlannedOptions}
          onFilterChange={handleFirstLessonPlannedFilterChange}
          filterValue={firstLessonPlannedFilter}
        />
      ),
      renderCell: (params) => <span>{params.value ? "Yes" : "No"}</span>,
    };

    // Find the index of the "Milestone" column
    const milestoneIndex = allColumns.findIndex((col) => col.field === "stage");

    // Insert the "First Lesson Planned" column right after the "Milestone" column
    if (milestoneIndex !== -1) {
      allColumns.splice(milestoneIndex + 1, 0, firstLessonPlannedColumn);
    }
  }

 
  

  const handleInputChange = (event, fieldName, enquiryId) => {
    const { value } = event.target;
    setEditedData((prev) => ({
      ...prev,
      [enquiryId]: {
        ...prev[enquiryId],
        [fieldName]: value, // Update the specific field (e.g., predicted_lessons, salesbook_status)
      },
    }));
  };

  const saveInlineEdit = async (clientId, enquiryId) => {
    const dataToUpdate = {
      ...editedData[enquiryId],
      predicted_lessons: parseInt(editedData[enquiryId]?.predicted_lessons, 10),
    };

    try {
      // Send the POST request to update the predicted lessons
      await axios.post(`/api/enquiries/${enquiryId}/predicted-lessons`, {
        predicted_lessons: dataToUpdate.predicted_lessons,
      });

      // Update the enquiry data in the local state without refetching
      setClients((prevClients) =>
        prevClients.map((enquiry) =>
          enquiry.id === enquiryId
            ? {
                ...enquiry,
                ...dataToUpdate,
                predictionAccuracy: calculatePredictionAccuracy(
                  dataToUpdate.predicted_lessons,
                  enquiry.totalLessonsComplete
                ), // Recalculate prediction accuracy
              }
            : enquiry
        )
      );

      setEditingRow(null);
    } catch (error) {
      console.error("Error saving enquiry data:", error.message);
    }
  };

  // Function to calculate prediction accuracy
  const calculatePredictionAccuracy = (
    predictedLessons,
    totalLessonsComplete
  ) => {
    if (predictedLessons === null || predictedLessons === undefined) {
      return "No Prediction";
    }
    return totalLessonsComplete >= predictedLessons
      ? "Accurate Prediction"
      : "Inaccurate Prediction";
  };

  const handleFetchAndUpdate = async () => {
    setLoading(true);
    try {
      await axios.post("/api/local/sync-data");
      await fetchEnquiries();
    } catch (error) {
      console.error("Error fetching and updating data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRecountSalesbookStats = async () => {
    setLoading(true);
    try {
      // Call the backend endpoint to recount salesbook stats
      await axios.post("/api/enquiries/recount-stats");

      // Refresh the enquiries list after the recount is completed
      await fetchEnquiries();
    } catch (error) {
      console.error("Error recounting salesbook stats:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const toggleShowAllColumns = () => {
    setShowAllColumns((prevShowAllColumns) => !prevShowAllColumns);
  };

  const cancelInlineEdit = () => {
    setEditingRow(null); // Reset editing state
  };

  const handleSaveClient = async (formData) => {
    console.log("Saving client with data:", formData); // Log the data being sent
    if (!formData) {
      console.error("No form data found. Exiting save operation.");
      return;
    }
    try {
      const response = await axios.put(
        `/api/clients/${formData.client_id}`,
        formData
      );
      console.log("Save response:", response.data); // Log the response data
      await fetchEnquiries(); // Re-fetch data after saving to update the UI
    } catch (error) {
      console.error("Error saving client:", error.message); // Log the error message
      console.error(
        "Error details:",
        error.response?.data || "No additional error details"
      ); // Log any additional error details
    }
  };

  const fetchClientData = async (clientId) => {
    try {
      console.log(`Fetching client data for client ID: ${clientId}`); // Log the clientId being used
      const response = await axios.get(`/api/clients/${clientId}`);
      console.log("Client data fetched from server:", response.data); // Log the response from the server
      return response.data;
    } catch (error) {
      console.error("Error fetching client data:", error.message);
      return {};
    }
  };

  const fetchEnquiryData = async (jobId) => {
    try {
      // Fetch clientCost and tutorPay from the backend
      const serviceResponse = await axios.get(
        `/api/tutorcruncher/job-details/${jobId}`
      );
      const { status, dft_charge_rate, dft_contractor_rate } =
        serviceResponse.data; // Ensure these keys are correct

      console.log(`Job Details for Job ID ${jobId}:`, serviceResponse.data);

      // Fetch appointments to calculate total lesson hours, revenue, etc.
      const appointmentsResponse = await axios.get(
        `/api/tutorcruncher/appointments`,
        {
          params: {
            serviceId: jobId,
            start_gte: "", // Add your start date if needed
            start_lte: "", // Add your end date if needed
          },
        }
      );

      const appointments = appointmentsResponse.data.results;
      let totalLessonsComplete = 0;
      let totallessonhourscomplete = 0;

      appointments.forEach((appointment) => {
        const startTime = new Date(appointment.start);
        const endTime = new Date(appointment.finish);

        if (!isNaN(startTime.getTime()) && !isNaN(endTime.getTime())) {
          const hours = (endTime - startTime) / (1000 * 60 * 60); // Convert milliseconds to hours
          totalLessonsComplete += 1;
          totallessonhourscomplete += hours;
        }
      });

      const avgLessonLength =
        totalLessonsComplete > 0
          ? (totallessonhourscomplete / totalLessonsComplete).toFixed(1)
          : "0.0";

      const clientCost = dft_charge_rate || 0;
      const tutorPay = dft_contractor_rate || 0;

      const revenue = clientCost * totallessonhourscomplete; // Calculate revenue
      const cost = tutorPay * totallessonhourscomplete; // Calculate tutor pay
      const profit = (revenue - cost).toFixed(2); // Calculate profit and then apply toFixed
      const packageprofit = (revenue - cost).toFixed(2); // Calculate profit and then apply toFixed
      const calculatedprofit = (revenue - cost).toFixed(2); // Calculate profit and then apply toFixed

      return {
        totalLessonsComplete,
        totallessonhourscomplete: totallessonhourscomplete.toFixed(1),
        avgLessonLength,
        revenue,
        cost,
        profit,
        clientCost, // Directly using client cost per hour
        tutorPay, // Directly using tutor pay per hour
        jobStatus: status || "Unknown",
      };
    } catch (error) {
      console.error(
        "Error fetching service or appointment data:",
        error.message
      );
      return {
        totalLessonsComplete: 0,
        totallessonhourscomplete: "0.0",
        avgLessonLength: "0.0",
        revenue: "0.00",
        cost: "0.00",
        profit: "0.00",
        clientCost: 0,
        tutorPay: 0,
        jobStatus: "Unknown",
      };
    }
  };

  const fetchTutorName = async (serviceId) => {
    try {
      const response = await axios.get(
        `/api/tutorcruncher/services/${serviceId}/tutor`
      );
      console.log(
        `Tutor Name for Service ID ${serviceId}:`,
        response.data.tutorName
      );
      return response.data.tutorName;
    } catch (error) {
      console.error("Error fetching tutor name:", error.message);
      return "N/A";
    }
  };

  const fetchJobDetails = async (jobId) => {
    try {
      // Fetch job details, including job status, dft_charge_rate, and dft_contractor_rate
      const response = await axios.get(
        `/api/tutorcruncher/job-details/${jobId}`
      );

      const { status, dft_charge_rate, dft_contractor_rate } = response.data;

      console.log(`Job Details for Job ID ${jobId}:`, {
        status,
        dft_charge_rate,
        dft_contractor_rate,
      });

      return {
        status,
        clientCost: dft_charge_rate,
        tutorPay: dft_contractor_rate,
      };
    } catch (error) {
      console.error(
        `Error fetching job details for Job ID ${jobId}:`,
        error.message
      );
      return {
        status: "Unknown",
        clientCost: 0,
        tutorPay: 0,
      };
    }
  };

  // const calculateStage = (totalLessonsComplete) => {
  //   if (totalLessonsComplete >= 10) return "10 Lessons Complete";
  //   if (totalLessonsComplete >= 5) return "5 Lessons Complete";
  //   if (totalLessonsComplete >= 3) return "3 Lessons Complete";
  //   if (totalLessonsComplete >= 1) return "First Lesson Complete";
  //   return "No Lessons Complete";
  // };

  const handleDeleteClient = async (clientId) => {
    try {
      await axios.delete(`/api/clients/${clientId}`);
      setClients(clients.filter((client) => client.id !== clientId));
    } catch (error) {
      console.error("Error deleting client:", error.message);
    }
  };

  const openEditDialog = (client) => {
    console.log("Client data passed to dialog:", client); // Ensure this logs the full client object
    setSelectedClient(client);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedClient(null);
  };

  const JobStatusLabel = ({ status }) => {
    const getStatusStyle = (status) => {
      switch (status) {
        case "pending":
          return "bg-gray-100 text-gray-800";
        case "available":
          return "bg-blue-100 text-blue-800";
        case "in-progress":
          return "bg-green-100 text-green-800";
        case "finished":
          return "bg-orange-100 text-orange-800";
        case "gone-cold":
          return "bg-red-100 text-red-800";
        default:
          return "bg-gray-100 text-gray-800";
      }
    };

    return (
      <span
        className={`inline-flex items-center px-2 py-1 rounded-md text-xs font-medium ${getStatusStyle(
          status
        )}`}
      >
        {status}
      </span>
    );
  };

  const saveClient = async (clientData) => {
    try {
      console.log("Saving client with data:", clientData);
      const response = await axios.put(
        `/api/clients/${clientData.client_id}`,
        clientData
      );
      console.log("Client updated successfully:", response.data);
    } catch (error) {
      if (error.response) {
        // Log details if the server responded with a status other than 2xx
        console.error("Error response from server:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request
        console.error("Error setting up the request:", error.message);
      }
    }
  };

  const markAsSeen = async (enquiryId) => {
    if (typeof enquiryId !== "number") {
      console.error("Invalid enquiry ID for marking as seen:", enquiryId);
      return;
    }
  
    try {
      // Ensure you're sending an integer ID, not a string like "bulk-delete"
      await axios.put(`/api/enquiries/${enquiryId}`, { seen: true });
  
      // Update local state to reflect the change immediately
      setClients((prevClients) =>
        prevClients.map((enquiry) =>
          enquiry.id === enquiryId ? { ...enquiry, seen: true } : enquiry
        )
      );
    } catch (error) {
      console.error("Error marking enquiry as seen:", error.message);
    }
  };
  

  return (
    <div className="mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="mt-3 text-3xl font-bold tracking-tight text-slate-900">
          Salesbook
        </h1>

       


        {/* Button to open date picker */}
        <button
          onClick={() => setShowDatePicker((prev) => !prev)}
          className="px-4 py-2 border rounded"
        >
          {dateRange.startDate.format("MMM DD, YYYY")} -{" "}
          {dateRange.endDate.format("MMM DD, YYYY")}
        </button>

        {/* Popover to show the StaticDateRangePicker */}
        <Popover
          open={showDatePicker}
          anchorEl={anchorEl}
          onClose={() => setShowDatePicker(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="p-4">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDateRangePicker
                displayStaticWrapperAs="desktop"
                value={[dateRange.startDate, dateRange.endDate]}
                onChange={handleDateRangeChange}
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                  actionBar: { actions: [] },
                }}
                calendars={2}
              />
            </LocalizationProvider>
          </div>
        </Popover>

        <div className="flex space-x-4">
          <button
            onClick={() => setTab("won")}
            className={`px-4 py-2 font-semibold rounded ${
              tab === "won" ? "bg-blue-500 text-white" : "bg-gray-200"
            }`}
          >
            Won
          </button>
          <button
            onClick={() => setTab("connected")}
            className={`px-4 py-2 font-semibold rounded ${
              tab === "connected" ? "bg-blue-500 text-white" : "bg-gray-200"
            }`}
          >
            Connected
          </button>
          <button
            onClick={() => setTab("lost")}
            className={`px-4 py-2 font-semibold rounded ${
              tab === "lost" ? "bg-red-500 text-white" : "bg-gray-200"
            }`}
          >
            Lost
          </button>

         






          <button
            onClick={() => setShowRecentUpdates((prev) => !prev)}
            className="px-4 py-2 font-semibold bg-yellow-500 text-white rounded"
          >
            {showRecentUpdates
              ? "Show All Entries"
              : "Show Entries with Updates"}
          </button>

          <button
            onClick={toggleShowAllColumns}
            className="px-4 py-2 font-semibold bg-green-500 text-white rounded"
          >
            {showAllColumns ? "Hide Columns" : "Show All Columns"}
          </button>

          {loggedInUser?.role === "Admin" && (
 // Disable the delete button unless the "lost" tab is active
<button
  onClick={handleBulkDelete}
  className="px-4 py-2 font-semibold bg-red-500 text-white rounded"
  disabled={tab !== "lost" || rowSelectionModel.length === 0} // Disable if not in "won" tab or no rows are selected
>
  Delete Selected
</button>


)}


     

        </div>
      </div>

      {loading ? (
        <div className="text-center">
          <div className="flex flex-col items-center justify-center h-screen">
            <div className="spinner-border animate-spin inline-block w-12 h-12 border-8 rounded-full border-t-blue-500 border-blue-300 mb-4"></div>
            <p className="text-xl font-semibold text-center">
              Loading {tab} salesbook entries...
            </p>
          </div>
        </div>
      ) : (
        <div className="overflow-x-auto"></div>
      )}

<ToastContainer />

<NotesDialog
  isOpen={isNotesDialogOpen}
  closeModal={() => setIsNotesDialogOpen(false)}
  enquiryId={selectedEnquiry}
  saveNote={handleSaveNote}
  notes={notes}
  deleteNote={handleDeleteNote} // Pass delete handler
  updateNote={handleUpdateNote} // Pass update handler
/>


      <SalesBookDialog
        isOpen={isDialogOpen}
        closeModal={closeDialog}
        client={selectedClient}
        saveClient={saveClient}
        onSave={handleSaveClient} // Pass the callback to refresh data
      />

<DataGridPro
  rows={filteredClients}
  columns={allColumns}
  pagination
  pageSizeOptions={[5, 10, 25, 50]}
  paginationModel={paginationModel}
  sortModel={sortModel}
  onSortModelChange={(newModel) => setSortModel(newModel)}
  onPaginationModelChange={(model) => setPaginationModel(model)}
  // Ensure that the getRowId function returns a unique ID for each row
  getRowId={(row) => row.id || row.enquiry_id}  // Use 'id' or fallback to 'client_id'
  filterModel={filterModel}
  onFilterModelChange={handleFilterModelChange}
  checkboxSelection={tab === "lost"}  // Enable checkbox selection only for the "lost" tab
  disableRowSelectionOnClick
  onRowSelectionModelChange={(newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel); // Update the selection model with selected rows
    console.log("Selected rows:", newRowSelectionModel); // Log the selected rows
  }}
  rowSelectionModel={rowSelectionModel}  // Use rowSelectionModel to track selected rows
/>





      <button
        onClick={handleFetchAndUpdate}
        className="px-4 py-2 font-semibold bg-blue-500 text-white rounded mr-4 mt-4"
      >
        Fetch & Update from TutorCruncher
      </button>

      <button
        onClick={handleRecountSalesbookStats}
        className="px-4 py-2 font-semibold bg-purple-500 text-white rounded mt-4"
      >
        Recount Salesbook Stats
      </button>
    </div>

    
  );
};

export default SalesBook;
